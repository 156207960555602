div.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  vertical-align: top;
  padding: 1rem;
}

div.loading > div {
  padding: 1rem;
}
