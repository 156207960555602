a {
  color: #e4e4e4;
}

a:hover {
  color: #c2c2c2;
}

a:visited {
  color: #bbbbbb;
}

button {
  color: #d8f3e0;
  border: 1px solid #3b3b3b;
  border-radius: 20rem;
  background: #3b3b3b;
  padding: 0.75rem 1.25rem;
  display: inline-block;
  font-weight: 600;
}

button:hover {
  cursor: pointer;
}

button:disabled {
  border: 1px solid #282828;
  color: #a8aca9;
  background: #282828;
}

button:disabled:hover {
  cursor: default;
}

div#root > div.loading {
  position: absolute;
  height: 100%;
  width: 100%;
}

.error {
  border: none;
  border-radius: 20rem;
  background: #ee0000;
  padding: 1rem 2rem;
  display: inline-block;
}

button.error:disabled {
  border: 1px solid #aa0000;
  color: #a8aca9;
  background: #aa0000;
}

button.error:disabled:hover {
  cursor: default;
}

.warning {
  border: none;
  border-radius: 20rem;
  background: #ff8800;
  padding: 1rem 2rem;
  display: inline-block;
}

.success {
  border: none;
  border-radius: 20rem;
  background: #1aa34a;
  padding: 1rem 2rem;
  display: inline-block;
}

.info {
  border: none;
  border-radius: 20rem;
  background: #0055ff;
  padding: 1rem 2rem;
  display: inline-block;
}
